import { graphql, useStaticQuery } from "gatsby";

export const useTrainings = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(limit: 1000, filter: { frontmatter: { templateKey: { eq: "training" } } }) {
        edges {
          node {
            frontmatter {
              seo {
                title
                description
                image {
                  publicURL
                }
              }
              name
              isFeatured
              reviews
              rating
              position
              batches {
                price
                duration
                start_date
                end_date
                isAvailable
                type
              }
              faqs {
                question
                answer
              }
              images {
                childImageSharp {
                  fluid(maxWidth: 120, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              courseContent {
                lesson_name
                lessonContent
              }
            }
            fields {
              slug
              frontmattermd {
                overview {
                  html
                }
                prerequisite {
                  html
                }
                benefits {
                  html
                }
              }
            }
          }
        }
      }
    }
  `);

  return data.allMarkdownRemark.edges
    .map(({ node }) => {
      return {
        ...node.frontmatter,
        slug: node.fields.slug,
        overview: node.fields.frontmattermd.overview.html,
        prerequisite: node.fields.frontmattermd.prerequisite.html,
        benefits: node.fields.frontmattermd.benefits.html,
      };
    })
    .sort((a, b) => a.position - b.position);
};
