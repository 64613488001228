import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";

import Layout from "../components/Layout";
import { useTrainings } from "../hooks/useTrainings";
import Underline from "../components/Underline";
import { H1 } from "../components/Typography";
import Button from "../components/Button";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import useSiteMetadata from "../components/SiteMetadata";

const TraningPageTemplate = ({ content }) => {
  const tranings = useTrainings();
  const { siteUrl } = useSiteMetadata();

  return (
    <>
      <GatsbySeo
        title={content.seo.title}
        description={content.seo.description}
        openGraph={{
          url: `${siteUrl}/shop`,
          title: content.seo.title,
          description: content.seo.description,
          images: [{ url: `${siteUrl}${content.seo.image.publicURL}` }],
          site_name: "MycoForest",
        }}
      />
      <section className="section">
        <div className="container">
          <H1 alignCenter>Traning</H1>
          <Underline />
          <ul className="content">
            {tranings.map((traning) => (
              <Training traning={traning} key={traning.slug} />
            ))}
          </ul>
        </div>
        <style jsx>{`
          section {
            background: #ffff;
            padding: 32px;
          }
          .container {
            max-width: 1278px;
            margin: 0 auto;
          }
          ul {
            list-style: none;
            padding: 0;
          }

          @media (max-width: 580px) {
            section {
              padding: 32px 20px;
            }
          }
        `}</style>
      </section>
    </>
  );
};

const Training = ({ traning }) => {
  return (
    <li>
      <div className="image-container">
        <Link to={traning.slug}>
          <PreviewCompatibleImage imageInfo={traning.images[0]} alt={traning.name} />
        </Link>
      </div>
      <h3>
        <Link to={traning.slug}>{traning.name}</Link>
      </h3>
      <div className="info">
        <div className="ratings-reviews">
          <span className="ratings">
            <span>{traning.rating}</span>
            <img
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMiI+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTYuNSA5LjQzOWwtMy42NzQgMi4yMy45NC00LjI2LTMuMjEtMi44ODMgNC4yNTQtLjQwNEw2LjUuMTEybDEuNjkgNC4wMSA0LjI1NC40MDQtMy4yMSAyLjg4Mi45NCA0LjI2eiIvPjwvc3ZnPg=="
              alt="star icon"
            />
          </span>
          <span className="reviews">{traning.reviews} Reviews</span>
        </div>
        <Button isInternal href={`${traning.slug}`}>
          Details
        </Button>
      </div>
      <style jsx>{`
        li {
          transition: all 0.25s;
          min-height: 64px;
          margin: 0;
          margin-right: 20px;
          font-family: "Lato", sans-serif;
          border: 1px solid #f7fafc;
          border-radius: 8px;
          transition: all 0.2s;
          background: #fff;
          width: calc(33.33% - 20px);
        }

        li:hover {
          transform: scale(1.05);
        }

        li:last-child {
          margin-right: 0;
        }

        li h3 {
          padding: 16px 4px;
          padding-bottom: 0;
          margin: 0;
        }

        li h3 :global(a) {
          color: #000;
        }

        li .info {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 16px 4px;
          padding-top: 0;
        }

        li :global(.btn-link) {
          width: 150px;
        }

        .ratings-reviews {
          display: flex;
          align-items: center;
          font-family: "Lato", sans-serif;
        }

        .ratings {
          line-height: normal;
          display: inline-block;
          color: #fff;
          padding: 2px 4px 2px 6px;
          border-radius: 3px;
          font-weight: 500;
          font-size: 16px;
          vertical-align: middle;
          background-color: #ff8100;
        }

        .ratings img {
          margin: 2px 0 0 2px;
          height: 10px;
        }

        .reviews {
          display: inline-block;
          margin-left: 8px;
          font-size: 16px;
        }

        @media (max-width: 600px) {
          li {
            margin: 16px;
          }
          li {
            border: none;
          }

          li h3 {
            padding: 16px 0;
            padding-bottom: 8px;
            font-size: 18px;
          }
          li .info {
            padding: 0;
            padding-bottom: 8px;
          }
        }

        @media (max-width: 460px) {
          li .info {
            align-items: baseline;
          }

          li h3 {
            padding-bottom: 0;
          }

          li :global(.btn-link) {
            margin-top: 12px;
            font-size: 14px;
            width: 130px;
          }
        }

        @media (max-width: 420px) {
          li {
            width: 100%;
          }
        }
      `}</style>
    </li>
  );
};

const TrainingPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout whiteHeader isShopPage>
      <TraningPageTemplate content={frontmatter} />
    </Layout>
  );
};

export default TrainingPage;

export const pageQuery = graphql`
  query TraningPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "training-page" } }) {
      frontmatter {
        seo {
          title
          description
          image {
            publicURL
          }
        }
      }
    }
  }
`;
